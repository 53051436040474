import { request } from "@/utils/service";
import { useUserStore } from "@/store/user";

function baseParams(isGzp: boolean | undefined = true) {
  const { token, uuid } = useUserStore();
  const params: IBaseParams = { user_id: uuid };

  if (token) params.access_token = token;

  if (isGzp) params.gzp = 1;

  return params;
}

// 获取首页聚合信息
export const getHomeInfo = () => {
  return request<IHomeInfoReturn>({
    url: "/api/home/info",
    method: "post",
    data: baseParams()
  });
};

const ORDER_RAND_NUM = Math.floor(Math.random() * 100);
// 获取食谱列表
type IRecipeParams = Partial<Pagination> & { id?: string; source_id?: string; tags?: string };
export const getFoodarticleList = (params: IRecipeParams) => {
  return request<ListData<IRecipe>>({
    url: "/api/foodarticle/list",
    method: "post",
    data: { ...baseParams(), ...params, order_rand_num: ORDER_RAND_NUM }
  });
};

// 获取食物百科列表
type IBaikeParams = Partial<Pagination> & { id?: string; classify?: string };
export const getFoodbaikeList = (params: IBaikeParams) => {
  return request<ListData<IRecipe>>({
    url: "/api/foodbaike/list",
    method: "post",
    data: { ...baseParams(false), ...params }
  });
};

// 获取节气美食列表
type IFoodsolarParams = Pagination & { id?: string; title?: string };
export const getFoodsolarList = (params: IFoodsolarParams) => {
  return request<ListData<ISolar>>({
    url: "/api/foodsolar/list",
    method: "post",
    data: params
  });
};

// 收藏菜谱
interface IUserCollectParams {
  object_id: string;
  object_type: "food_baike" | "food_article";
  type: 0 | 1 | "0" | "1";
}
export const userobjectcollectAdd = (params: IUserCollectParams) => {
  return request<boolean>({
    url: "/api/userobjectcollect/add",
    method: "post",
    data: { ...baseParams(), ...params }
  });
};
