import { getUrlParams } from "@/utils";
import type { RouteRecordRaw } from "vue-router";

export enum pathNames {
  guide = "Guide",
  guideResult = "Home",
  home = "TabHome",
  tabRecipe = "TabRecipe",
  tabBaike = "TabBaike",
  tabTopic = "TabTopic",
  foodSolar = "FoodSolar",
  recipeDetail = "RecipeDetail",
  foodbaikeDetail = "FoodbaikeDetail",
  topicsEmoji = "TopicsEmoji",
  topicsFoodpart = "TopicsFoodpart",
  topicsToponym = "TopicsToponym",
  topicsBattle = "TopicsBattle"
}

const getRedirect = function () {
  const query = getUrlParams();

  // 如果存在 地址中存在pathName 并且存在与路由表中 则将pathName设置为baseRoutePath
  if (query.pathName && Object.values(pathNames).includes(query.pathName)) return { name: query.pathName };

  if (!process.env.VUE_APP_START) return "/home";
  else if (process.env.VUE_APP_START == "guide") return "/guide";
  else return "/home";
};

export default <RouteRecordRaw[]>[
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/guide",
    name: pathNames.guide,
    // component: () => import("@/views/guide/GuideView.vue"),
    component: () => import("@/views/guide/GuideViewNew.vue"),
    meta: {
      hideFixedHome: false,
      spaceKeys: ["Guide_banner", "Guide_native"]
    }
  },
  {
    path: "/guideResult",
    name: pathNames.guideResult,
    component: () => import("@/views/guide/GuideResultView.vue"),
    spaceKeys: ["ListInter", "ListBanner", "ListNative", "ListNativeCenter1", "ListNativeCenter2"]
  },
  {
    path: "/home",
    redirect: "/home/tabMain",
    component: () => import(/* webpackPrefetch: true  */ "@/views/home/HomeView.vue"),
    meta: {
      hideFixedHome: false
    },
    children: [
      {
        path: "/home/tabMain",
        name: pathNames.home,
        component: () => import(/* webpackPrefetch: true  */ "@/views/home/tab-content/TabMainView.vue"),
        meta: {
          spaceKeys: ["HomeNative", "HomeBanner", "HomeDialogInter"]
        }
      },
      {
        path: "/home/tabRecipe",
        name: pathNames.tabRecipe,
        component: () => import(/* webpackPrefetch: true  */ "@/views/home/tab-content/TabRecipeView.vue"),
        meta: {
          spaceKeys: ["HomeNative", "HomeBanner", "HomeDialogInter"]
        }
      },
      {
        path: "/home/tabBaike",
        name: pathNames.tabBaike,
        component: () => import(/* webpackPrefetch: true  */ "@/views/home/tab-content/TabBaikeView.vue"),
        meta: {
          spaceKeys: ["HomeNative", "HomeBanner", "HomeDialogInter"]
        }
      },
      {
        path: "/home/tabTopic",
        name: pathNames.tabTopic,
        component: () => import(/* webpackPrefetch: true  */ "@/views/home/tab-content/TabTopicView.vue"),
        meta: {
          spaceKeys: ["HomeBanner", "HomeDialogInter"]
        }
      }
    ]
  },
  {
    path: "/foodsolar",
    name: pathNames.foodSolar,
    component: () => import(/* webpackPrefetch: true  */ "@/views/foodsolar/FoodsolarView.vue"),
    props: (route) => ({ index: route.query.index }),
    meta: {
      title: "24节气",
      spaceKeys: ["SolarDetailBanner"]
    }
  },
  {
    path: "/recipeDetail",
    name: pathNames.recipeDetail,
    props: (route) => ({
      id: route.query.id,
      return: route.query.return,
      dpEnter: route.query.dpEnter
    }),
    component: () => import(/* webpackPrefetch: true  */ "@/views/recipe/RecipeDetailView.vue"),
    meta: {
      title: "菜谱详情",
      spaceKeys: ["RecipeDetailBanner", "RecipeDetailNative"]
    }
  },
  {
    path: "/foodbaikeDetail",
    name: pathNames.foodbaikeDetail,
    props: (route) => ({ id: route.query.id }),
    component: () => import(/* webpackPrefetch: true  */ "@/views/foodbaike/FoodbaikeView.vue"),
    meta: {
      title: "美食百科详情",
      spaceKeys: ["BaikeDetailBanner", "BaikeDetailNative"]
    }
  },
  {
    path: "/topics/emoji",
    name: pathNames.topicsEmoji,
    component: () => import(/* webpackPrefetch: true  */ "@/views/topics/TopicsEmojiView.vue"),
    meta: {
      title: "emoji大猜想",
      spaceKeys: ["TopicDetailBanner", "TopicDetailNative", "AnswerInter"]
    }
  },
  {
    path: "/topics/foodpart",
    name: pathNames.topicsFoodpart,
    component: () => import(/* webpackPrefetch: true  */ "@/views/topics/TopicsFoodpartView.vue"),
    meta: {
      title: "看美食猜地名",
      spaceKeys: ["TopicDetailBanner", "TopicDetailNative", "AnswerInter"]
    }
  },
  {
    path: "/topics/toponym",
    name: pathNames.topicsToponym,
    component: () => import(/* webpackPrefetch: true  */ "@/views/topics/TopicsToponymView.vue"),
    meta: {
      title: "看局部猜美食",
      spaceKeys: ["TopicDetailBanner", "TopicDetailNative", "AnswerInter"]
    }
  },
  {
    path: "/topics/battle",
    name: pathNames.topicsBattle,
    component: () => import(/* webpackPrefetch: true  */ "@/views/topics/TopicsBattleView.vue"),
    meta: {
      title: "南北饮食大PK",
      spaceKeys: ["TopicDetailBanner", "TopicDetailNative", "AnswerInter"]
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackPrefetch: true  */ "@/views/NotFoundView.vue"),
    meta: {
      title: "404"
    }
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404"
  }
];
