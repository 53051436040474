export const randomString = (len: number) => {
  len = len || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length,
    n = "";
  for (let i = 0; i < len; i++) {
    let index = Math.floor(Math.random() * a);
    let str = t.substr(index, 1);
    n += str;
  }
  return n;
};

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getUrlParams = function (url?: string) {
  url = url || window.location.toString();
  let paramsStr = url.split("?")[1];

  if (!paramsStr) return {};

  if (paramsStr.indexOf("#") >= 0) {
    paramsStr = paramsStr.split("#")[0];
  }
  if (!paramsStr) return {};

  return paramsStr
    .split("&")
    .map((item) => item.split("="))
    .reduce((prev, [key, val]) => ((prev[key] = val), prev), {} as { [key: string]: any });
};

export const formatNumber = function (numStr: number | string) {
  if (!numStr) {
    return 0;
  }
  numStr = `${numStr}`;
  let num = parseInt(numStr);
  if (numStr.length < 5) {
    return num;
  } else if (numStr.length < 11) {
    return (((num / 10000) * 10) / 10).toFixed(1) + "w";
  } else {
    return 0;
  }
};

/* 根据日期计算节气
 * 参数：(年份, 月份, 日期)
 * 无法精确判断特殊的年份
 */
const calcSolarTerms = function (yyyy: number, mm: number, dd: number) {
  mm = mm - 1;
  var sTermInfo = new Array(
    0,
    21208,
    42467,
    63836,
    85337,
    107014,
    128867,
    150921,
    173149,
    195551,
    218072,
    240693,
    263343,
    285989,
    308563,
    331033,
    353350,
    375494,
    397447,
    419210,
    440795,
    462224,
    483532,
    504758
  );
  var solarTerm = new Array(
    "小寒",
    "大寒",
    "立春",
    "雨水",
    "惊蛰",
    "春分",
    "清明",
    "谷雨",
    "立夏",
    "小满",
    "芒种",
    "夏至",
    "小暑",
    "大暑",
    "立秋",
    "处暑",
    "白露",
    "秋分",
    "寒露",
    "霜降",
    "立冬",
    "小雪",
    "大雪",
    "冬至"
  );
  var tmp1 = new Date(31556925974.7 * (yyyy - 1900) + sTermInfo[mm * 2 + 1] * 60000 + Date.UTC(1900, 0, 6, 2, 5));
  var tmp2 = tmp1.getUTCDate();
  var solarTerms = "";

  if (tmp2 == dd) {
    solarTerms = solarTerm[mm * 2 + 1];
  }

  tmp1 = new Date(31556925974.7 * (yyyy - 1900) + sTermInfo[mm * 2] * 60000 + Date.UTC(1900, 0, 6, 2, 5));
  tmp2 = tmp1.getUTCDate();

  if (tmp2 == dd) {
    solarTerms = solarTerm[mm * 2];
  }

  return solarTerms;
};

/**
 * 展示当天系统日期后面的最近一个节气（包括今天）。
 */
export const getAfterRecentSolarTerms = function () {
  // 最大递归次数
  const breakCount = 20;
  // 获取当前系统时间 年月日
  const date = new Date();
  const [year, month, day] = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  // 获取当月有多少天
  const dayCount = new Date(year, month, 0).getDate();

  const getSolarTerms = function (yyyy: number, mm: number, dd: number, count: number = 1): string | void {
    if (count > breakCount) return;

    const solarTerm = calcSolarTerms(yyyy, mm, dd);

    // 判断天数是否到当月天数总数
    if (dd == dayCount) {
      // 判断月份是否到今年月份总数
      if (mm == 12) {
        mm = 1;
      } else {
        mm++;
      }
      dd = 1;
    } else {
      dd++;
    }

    return solarTerm || getSolarTerms(yyyy, mm, dd, count + 1);
  };

  return getSolarTerms(year, month, day) || "立春";
};
