import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from "axios";
import { showFailToast } from "vant";
import { base64decode, isBase64 } from "./base64";
import pako from "pako";

function unzip(b64Data: string) {
  let decJson = base64decode(b64Data);
  // Convert binary string to character-number array
  let charData = decJson.split("").map(function (x) {
    return x.charCodeAt(0);
  });
  // Turn number array into byte-array
  let binData = new Uint8Array(charData);
  // // unzip
  let data = pako.inflate(binData);
  // Convert gunzipped byteArray back to ascii string:
  // @ts-ignore
  decJson = String.fromCharCode.apply(null, new Uint16Array(data));
  return decJson;
}

/** 创建请求实例 */
function createService() {
  const service = axios.create();
  service.interceptors.request.use(
    (config: any) => {
      return config;
    },
    (error: any) => Promise.reject(error)
  );
  service.interceptors.response.use(
    (response: AxiosResponse) => {
      if (isBase64(response.data)) {
        response.data = JSON.parse(unzip(response.data) || "");
      }

      const apiData = response.data as IApiResponseData<any>;

      const code = apiData.result;

      switch (code) {
        case 0:
          return apiData.data;
        default:
          showFailToast(apiData.msg);
          return Promise.reject(apiData.msg || "error");
      }
    },
    (error: any) => Promise.reject(error)
  );
  return service;
}

/** 创建请求方法 */
function createRequestFunction(service: AxiosInstance) {
  return function <T>(config: AxiosRequestConfig): Promise<T> {
    const configDefault = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      baseURL: process.env.VUE_APP_URL
    };
    return service(Object.assign(configDefault, config));
  };
}

/** 用户网络请求的实例 */
export const service = createService();

/** 用户网络请求的方法 */
export const request = createRequestFunction(service);
